// eslint-disable-next-line import/no-extraneous-dependencies
import { Checkbox, Col, Form, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { ValidatorRule } from 'rc-field-form/es/interface';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { InCreation, IWorkIntervalLocal, IWorkIntervalUtc } from '../../../../cleaning/interfaces/CleaningPlan.types';
import { CleaningActions } from '../../../../cleaning/state/cleaningActions';
import { SiteModalsActions } from '../../state/siteModalsActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import * as siteDetailsPanelSelectors from '../../../site-details-panel/state/siteDetailsPanelSelectors';
import { StyledAddWorkIntervalModal } from './AddWorkIntervalModal.styles';
import { GRAPHQL_MAX_INT_VALUE } from 'config/constants';
import { CleaningPlanUtils } from 'app/modules/cleaning/utils/CleaningPlanUtils';
import { MachineClassification, Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ToastService } from 'app/cross-cutting-concerns/toasts/ToastService';
import { SiteDetailsValidationError } from 'app/modules/site-management/site-details/ValidationError';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { Optional } from 'lib/types/Optional';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { TimePicker } from 'lib/components/TimePicker/TimePicker';
import DurationPicker from 'app/utils/DurationPicker/DurationPicker';

export interface AddWorkIntervalFormValues {
  daysOfWeekLocal: number[];
  startTime: Dayjs;
  endTime: Dayjs;
  machine: string;
  // durationMinutes: number;
  plannedDurationMs: number;
}

export interface AddWorkIntervalModalProps {
  site: Site;
}

const toastService = new ToastService();

export const AddWorkIntervalModal = ({ site }: AddWorkIntervalModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();

  const startTimeValue = Form.useWatch('startTime', formInstance);
  const endTimeValue = Form.useWatch('endTime', formInstance);
  const durationValue = Form.useWatch('plannedDurationMs', formInstance);

  useEffect(() => {
    if (startTimeValue && durationValue) {
      formInstance.validateFields(['endTime']);
    }
  }, [formInstance, startTimeValue, durationValue, endTimeValue]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(siteModalsSelectors.selectIsAddWorkIntervalModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsAddWorkIntervalModalLoading);
  const availableSiteMachines = useSelector(siteModalsSelectors.selectAvailableSiteMachines);
  const areAvailableSiteMachinesLoading = useSelector(siteModalsSelectors.selectAreAvailableSiteMachinesLoading);
  const createError = useSelector(siteDetailsPanelSelectors.selectCreateWorkIntervalError);

  const [fields, setFields] = useState<Optional<AddWorkIntervalFormValues>>(undefined);
  const [isWorkIntervalOpen, setIsWorkIntervalOpen] = useState<boolean>(false);

  let daysOfWeekOptions = [
    { label: 'Mon', value: 1 },
    { label: 'Tue', value: 2 },
    { label: 'Wed', value: 3 },
    { label: 'Thu', value: 4 },
    { label: 'Fri', value: 5 },
    { label: 'Sat', value: 6 },
    { label: 'Sun', value: 7 },
  ];
  try {
    const tDaysOfWeekOptions = t('siteDetails.addWorkIntervalModal.daysOfWeekOptions', { returnObjects: true });
    if (typeof tDaysOfWeekOptions === 'string') {
      daysOfWeekOptions = JSON.parse(tDaysOfWeekOptions ?? []);
    } else {
      daysOfWeekOptions = tDaysOfWeekOptions;
    }
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    formInstance.resetFields();
  }, [formInstance, isVisible]);

  useEffect(() => {
    if (isVisible) {
      dispatch(
        SiteModalsActions.availableSiteMachinesRequest({
          id: site.id,
          filter: {
            classifications: [MachineClassification.Gcd, MachineClassification.Robot],
          },
          machinePaginationOptions: {
            limit: GRAPHQL_MAX_INT_VALUE,
          },
        })
      );
    }
  }, [dispatch, isVisible, site.id]);

  useEffect(() => {
    if (createError) {
      toastService.error({
        message: t('toasts.validationError.title') as string,
        description: t(SiteDetailsValidationError[createError.message as keyof typeof SiteDetailsValidationError], {
          machineName:
            availableSiteMachines.find(machine => machine.id === formInstance.getFieldValue('machine'))?.name || '',
        }),
      });
    }
  }, [availableSiteMachines, createError, formInstance, t]);

  const handleCancel = useCallback(() => {
    dispatch(SiteModalsActions.hideAddWorkIntervalModal());
  }, [dispatch]);

  const handleOk = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const onFinish = useCallback(
    ({ machine, startTime, endTime, daysOfWeekLocal, plannedDurationMs }: AddWorkIntervalFormValues) => {
      const workIntervalLocal: InCreation<IWorkIntervalLocal> = CleaningPlanUtils.createLocalWorkIntervalInCreation(
        machine,
        startTime,
        endTime,
        daysOfWeekLocal,
        plannedDurationMs
      );

      const workIntervalUtc: InCreation<IWorkIntervalUtc> =
        CleaningPlanUtils.convertLocalWorkIntervalToUtc(workIntervalLocal);
      analyticsLinkActivated({
        linkName: AnalyticsLink.ADD_WORK_INTERVAL,
      });

      dispatch(
        CleaningActions.createWorkIntervalRequest({
          siteId: site.id,
          workIntervalUtc,
        })
      );
    },
    [analyticsLinkActivated, dispatch, site.id]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.ADD_WORK_INTERVAL,
    // serializable timeSpanLocal before saving to redux since it's dayjs type currently
    fields: (fields?.startTime && fields?.endTime
      ? {
          ...fields,
          startTime: dayjs(fields.startTime).toISOString(),
          endTime: dayjs(fields.endTime).toISOString(),
        }
      : fields) as Record<string, any>,
    isVisible,
  });

  const normalizedDuration = (timeDuration: Dayjs): any => {
    const d = (timeDuration.hour() * 60 + timeDuration.minute()) * 60 * 1000;
    return d;
  };

  const isEndTimeValid: ValidatorRule['validator'] = (_, value) => {
    if (value && startTimeValue && dayjs(value).isBefore(dayjs(startTimeValue).add(durationValue, 'milliseconds'))) {
      return Promise.reject(t('timeRangeValidator.errors.endTimeMustAfterStartTimePlusDuration'));
    }
    return Promise.resolve(true);
  };

  return (
    <StyledAddWorkIntervalModal
      className="add-work-interval-modal"
      title={t('siteDetails.addWorkIntervalModal.modalTitle')}
      open={isVisible}
      width={600}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.add')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formInstance}
        name="add-work-interval"
        layout="vertical"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="daysOfWeekLocal"
              required
              rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.daysOfWeekRequired') }]}
            >
              <Checkbox.Group className="add-work-interval-modal__days-of-week" options={daysOfWeekOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="startTime"
              label={t('siteDetails.addWorkIntervalModal.startTime')}
              required
              rules={[{ required: true, message: t('timeRangeValidator.errors.startTimeRequired') }]}
            >
              <TimePicker
                use12Hours={false}
                minuteStep={5}
                hourStep={1}
                format="HH:mm"
                className="add-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="plannedDurationMs"
              label={t('siteDetails.addWorkIntervalModal.duration')}
              required
              normalize={normalizedDuration}
              initialValue={3600000}
              rules={[{ required: true, message: t('timeRangeValidator.errors.durationRequired') }]}
            >
              <DurationPicker
                use12Hours={false}
                minuteStep={15}
                hourStep={1}
                className="add-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="endTime"
              label={t('siteDetails.addWorkIntervalModal.endTime')}
              required
              rules={[
                { required: true, message: t('timeRangeValidator.errors.endTimeRequired') },
                {
                  validator: isEndTimeValid,
                },
              ]}
            >
              <TimePicker
                use12Hours={false}
                minuteStep={5}
                hourStep={1}
                format="HH:mm"
                className="add-work-interval-modal__time-range-picker"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="machine"
              label={t('siteDetails.addWorkIntervalModal.device')}
              required
              rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.machineRequired') }]}
            >
              <Select
                className="add-work-interval-modal__dropdown"
                optionLabelProp="name"
                maxTagCount="responsive"
                loading={areAvailableSiteMachinesLoading}
                filterOption={(input, option): boolean => option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
                options={[
                  ...(availableSiteMachines.map(siteMachine => ({
                    label: [siteMachine.name, siteMachine.type?.name].filter(Boolean).join(', '),
                    value: siteMachine.id,
                    name: siteMachine.name,
                  })) || []),
                ]}
                dropdownVisibleState={isWorkIntervalOpen}
                onDropdownVisibleChange={(isOpen: boolean): void => setIsWorkIntervalOpen(isOpen)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledAddWorkIntervalModal>
  );
};
