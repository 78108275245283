import { produce } from 'immer';
import { AnyAction } from 'redux';
import { SiteWidgetsFleetDashboardActions } from './siteWidgetsFleetDashboardActions';
import { Optional } from 'lib/types/Optional';
import { SiteWithOperatingTime } from 'app/modules/site-management/interfaces/Site.types';

export interface SiteWidgetsFleetDashboardState {
  data: Optional<SiteWithOperatingTime[]>;
  isLoading: boolean;
}

export const initialState: SiteWidgetsFleetDashboardState = {
  data: null,
  isLoading: false,
};

export const siteWidgetsFleetDashboardReducer = (
  state = initialState,
  action: AnyAction
): SiteWidgetsFleetDashboardState =>
  produce(state, draft => {
    switch (action.type) {
      case SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST: {
        if (!draft.data) {
          draft.isLoading = true;
        }
        return draft;
      }

      case SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_SUCCESS: {
        const {
          sites: { data },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        return draft;
      }

      case SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case SiteWidgetsFleetDashboardActions.RESET_STATE: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
