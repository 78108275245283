import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, select } from 'typed-redux-saga';
import isEqual from 'lodash-es/isEqual';
import { IDependencies } from '../../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetSiteListWithOperatingTimeRequestAction,
  IGetSiteListWithOperatingTimeSuccessAction,
  IGetSiteListWithOperatingTimeErrorAction,
  SiteWidgetsFleetDashboardActions,
} from './siteWidgetsFleetDashboardActions';
import { SiteWidgetsFleetDashboardSelectors } from './siteWidgetsFleetDashboardSelectors';
import { SiteListWithOperatingTime } from 'app/modules/site-management/interfaces/Site.types';
import { LoadingBarActions } from 'app/cross-cutting-concerns/loading-bar/state/loadingBarSlice';

export function* getSiteListWithOperatingTimeSaga(
  action: IGetSiteListWithOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<SiteListWithOperatingTime>
  | PutEffect<IGetSiteListWithOperatingTimeSuccessAction>
  | PutEffect<IGetSiteListWithOperatingTimeErrorAction>
  | PutEffect<
      ReturnType<typeof LoadingBarActions.showLoadingBar> | ReturnType<typeof LoadingBarActions.hideLoadingBar>
    >,
  void,
  IDependencies
> {
  const cachedData = yield* select(SiteWidgetsFleetDashboardSelectors.selectData);
  if (cachedData) {
    yield* put(LoadingBarActions.showLoadingBar());
  }

  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listSitesWithOperatingTime, action.payload);
    if (!isEqual(response.sites.data, cachedData)) {
      yield* put(SiteWidgetsFleetDashboardActions.getSiteListWithOperatingTimeSuccess(response));
    }
  } catch (error) {
    yield* put(
      SiteWidgetsFleetDashboardActions.getSiteListWithOperatingTimeError({
        error,
      })
    );
  } finally {
    // Hide loading bar if data is cached
    if (cachedData) {
      yield* put(LoadingBarActions.hideLoadingBar());
    }
  }
}

export function* siteWidgetsFleetDashboardSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(
    SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST,
    getSiteListWithOperatingTimeSaga
  );
}
